export const close = function() {

  const btn = [...document.querySelectorAll('.e-close')];
  if (!btn.length) return;

  const components = [
    '.c-alert',
    '.c-message',
    '.e-tag',
    '.e-card'
  ];

  btn.forEach(btn => {
    btn.addEventListener('click', (e) => {
      components.filter(c => {
        let target = btn.closest(c);
        if (target) {
          target.classList.add('is-closing');
          target.addEventListener('animationend', (e) => {
            target.classList.remove('is-closing');
            target.remove();
          });
        } else {
          return false;
        }
      });
    });
  });

};
