import MicroModal from 'micromodal';

export const microModal = function() {
  MicroModal.init({
    disableFocus: true,
    disableScroll: false,
    awaitCloseAnimation: true
  });
  const triggers = document.querySelectorAll('a[data-micromodal-trigger]');
  if (!triggers.length) return;
  triggers.forEach(trigger => trigger.setAttribute('href', 'javascript:void(0)'));
};
