import { getSiblings } from './helpers';

export const accordion = function() {

  const btn = [...document.querySelectorAll('.c-accordion__title')];
  if (!btn.length) return;

  btn.map((btn, i) => {
    btn.addEventListener('click', function() {

      if (this.getAttribute('aria-expanded') === 'true') {

        this.classList.remove('is-active');
        this.setAttribute('aria-expanded', 'false');

        this.nextElementSibling.classList.remove('is-open');
        this.nextElementSibling.setAttribute('aria-hidden', 'true');

      } else {

        this.classList.add('is-active');
        this.setAttribute('aria-expanded', 'true');

        this.nextElementSibling.classList.add('is-open');
        this.nextElementSibling.setAttribute('aria-hidden', 'false');
      }

      let siblings = getSiblings(this.closest('.c-accordion__item'));

      siblings.forEach(sibling => {
        let title = sibling.querySelector('.c-accordion__title');
        let panel = sibling.querySelector('.c-accordion__body');

        title.classList.remove('is-active');
        title.setAttribute('aria-expanded', 'false');

        panel.classList.remove('is-open');
        panel.setAttribute('aria-hidden', 'true');

      });

    });
  });

};
