export const dropdown = () => {
  const dd = [...document.querySelectorAll('.c-dropdown')];
  if (!dd.length) return;
  let ddArr = [];

  function Dropdown(el, btn, panel) {
    this.el = el;
    this.btn = btn;
    this.panel = panel;

    this.isOpen = -1;

    this.open = function() {
      this.isOpen = true;


      this.panel.classList.add('is-opening');
      this.panel.addEventListener('animationend', function(e) {

        panel.classList.remove('is-opening');
        panel.classList.add('is-open');

        el.classList.add('is-open');


      });

      this.btn.classList.add('is-active');
      this.btn.setAttribute('aria-expanded', 'true');


    };
    this.close = function() {
      this.isOpen = false;

      this.panel.classList.remove('is-opening');
      this.panel.classList.remove('is-open');
      this.panel.classList.add('is-closing');

      this.panel.addEventListener('animationend', function(e) {
        panel.classList.remove('is-closing');
        panel.classList.remove('is-open');

        el.classList.remove('is-open');

      });

      this.btn.classList.remove('is-active');
      this.btn.setAttribute('aria-expanded', 'false');


    };

    this.checkState = function() {
      this.isOpen = el.classList.contains('is-open') && btn.getAttribute('aria-expanded') === 'true' ? true : false;
    };

    this.changeState = function() {
      this.isOpen = !this.isOpen;

      if (this.isOpen === true) {
        this.open();
      } else {
        this.close();
      }
    };

  }

  dd.forEach(dd => {
    let obj = new Dropdown(dd, dd.querySelector('.c-dropdown__toggle'), dd.querySelector('.c-dropdown__menu'));
    obj.checkState();
    ddArr.push(obj);
  });

  ddArr.map((dd, index) => {
    dd.btn.addEventListener('click', function(e) {
      let btn = this;
      dd.changeState();
      closeOtherDropdowns(e, index, btn);
    });
  });

  function closeOtherDropdowns(e, index, btn) {
    ddArr.forEach((dd, i) => {
      if (i !== index && !dd.btn.closest('.c-dropdown').contains(btn)) dd.close();
    });
  }

  function closeDropdowns() {
    ddArr.forEach(dd => {
      dd.close();
    });
  }

  window.addEventListener('keydown', function(e) {
    if (e.key === 'Escape') {
      closeDropdowns();
    }
    if (e.key === 'Enter' && (!e.target.classList.contains('c-dropdown__toggle'))) {
      closeDropdowns();
    }
  });

  window.addEventListener('click', function(e) {
    if (e.target.closest('.c-dropdown') === null) {
      closeDropdowns();
    } else if (e.target.closest('.c-dropdown') !== null && e.target.closest('.c-dropdown__toggle') === null && e.target.closest('.c-dropdown__menu') === null) {
      closeDropdowns();
    }
  });

};
