import Tablist from '@accede-web/tablist';

export const tabs = function() {
  let tabsTrigger = [...document.querySelectorAll('.js-tabs')];
  if (!tabsTrigger.length) return;
  tabsTrigger.forEach(tabs => {
    let tablist = new Tablist(tabs);
    tablist.mount();
  });
};
