export const collapsible = function() {

  function init() {
    const collapsible = document.querySelectorAll('.js-collapsible');

    if (!collapsible.length) return;

    collapsible.forEach((el, i) => {

      const parent = el;
      const toggle = el.querySelector('.js-collapsible__toggle');
      const panel = el.querySelector('.js-collapsible__panel');

      const collapsible = {
        el: parent,
        toggle: toggle,
        panel: panel,
        open: false
      };

      // Expand
      function expand() {
        collapsible.el.classList.add('is-open');
        collapsible.toggle.setAttribute('aria-expanded', 'true');
        collapsible.panel.setAttribute('aria-hidden', 'false');
      }

      // Collapse
      function collapse() {
        collapsible.el.classList.remove('is-open');
        collapsible.toggle.setAttribute('aria-expanded', 'false');
        collapsible.panel.setAttribute('aria-hidden', 'true');
      }

      // Check and setup initial state
      if ( parent.classList.contains('is-open') ) {
        collapsible.open = true;
      } else {
        collapsible.open = false;
      }

      // Switch state
      collapsible.toggle.addEventListener('click', function() {
        collapsible.open = !collapsible.open;

        if (collapsible.open) {
          expand();
        } else {
          collapse();
        }

      });

    });
  }

  window.addEventListener('DOMContentLoaded', init);

};
