export const menuToggle = function() {
  const toggleClass = 'js-menu-toggle';
  const toggle = [...document.querySelectorAll(`.${toggleClass}`)];

  if (!toggle.length) return;

  toggle.forEach(el => {

    window.addEventListener('click', function(e) {

      if (e.target.closest('.js-menu-toggle') !== null) {
        document.body.classList.toggle('menu-open');
        e.target.closest('.js-menu-toggle').classList.toggle('is-active');
      } else if (e.target.closest('.c-nav') !== null ) {
        return;
      } else {
        document.body.classList.remove('menu-open');
        el.classList.remove('is-active');
      }

    });

    window.addEventListener('keydown', function(e) {

      var drop = [...document.querySelectorAll('.c-nav .c-dropdown.is-open')];

      if (e.key === 'Escape' && !drop.length) {
        document.body.classList.remove('menu-open');
        el.classList.remove('is-active');
      }
    });

  });

};
