import './vendor';

// Components
import {menuToggle} from './menu-toggle';
import {dropdown} from './dropdown';

import {tabs} from './tabs';
import {accordion} from './accordion';

import {microModal} from './modal';
import {close} from './close';
import {collapsible} from './collapsible';

// WAI keyboard focus improvements
import {waiTabFocus} from './wai-tab-focus';

// Functions object
const MainJs = {
  menuToggle: menuToggle,
  dropdown: dropdown,
  tabs: tabs,
  accordion: accordion,
  microModal: microModal,
  close: close,
  collapsible: collapsible,
  waiTabFocus: waiTabFocus,
};

// Initialization
Object.keys(MainJs).forEach(function(key) {
  MainJs[key]();
});

// Expose functions as global variable
window.MainJs = MainJs;
